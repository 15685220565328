var columns = [{
  title: 'UID',
  dataIndex: 'userid',
  key: 'userid',
  // width: '7%',
  scopedSlots: {
    customRender: 'userid'
  }
}, {
  title: '商家名称',
  dataIndex: 'userName',
  key: 'userName',
  // width: '7%',
  scopedSlots: {
    customRender: 'userName'
  }
},
// {
//     title: '商家类型',
//     dataIndex: 'userTypeName',
//     key: 'userTypeName',
//     // width: '5%',
//     scopedSlots: {
//         customRender: 'userTypeName'
//     }
// },
{
  // title: '邮箱',
  dataIndex: 'userEmail',
  key: 'userEmail',
  // width: '10%',
  scopedSlots: {
    customRender: 'userEmail'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '事项',
  dataIndex: 'businessTypeName',
  key: 'businessTypeName',
  // width: '10%',
  scopedSlots: {
    customRender: 'businessTypeName'
  }
},
// {
//     title: '交易金额($)',
//     dataIndex: 'amount',
//     key: 'amount',
//     // width: '10%',
//     scopedSlots: {
//         customRender: 'amount'
//     }
// },
// {
//     title: '手续费($)',
//     dataIndex: 'premium',
//     key: 'premium',
//     // width: '10%',
//     scopedSlots: {
//         customRender: 'premium'
//     }
// },
{
  title: '到账金额($)',
  dataIndex: 'incomefee',
  key: 'incomefee',
  // width: '10%',
  scopedSlots: {
    customRender: 'incomefee'
  }
}, {
  title: '余额($)',
  dataIndex: 'inventory',
  key: 'inventory',
  // width: '10%',
  scopedSlots: {
    customRender: 'inventory'
  }
}, {
  title: '流水号',
  dataIndex: 'flowno',
  key: 'flowno',
  // width: '10%',
  scopedSlots: {
    customRender: 'flowno'
  }
}, {
  title: '订单号/提现单号',
  dataIndex: 'datano',
  key: 'datano',
  // width: '10%',
  scopedSlots: {
    customRender: 'datano'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '5%',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };