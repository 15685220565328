var searchKeys = [{
  key: 'dates',
  label: '时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
},
// {
//     key: "type",
//     label: "商家类型",
//     placeholder: "全部",
//     required: false,
//     rules: [],
//     select: true,
// },
{
  key: "businessType",
  label: "事项",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "flowNo",
  label: "流水号",
  placeholder: "流水号",
  required: false,
  rules: [],
  input: true
}, {
  key: "dataNo",
  label: "订单号/提现单号",
  placeholder: "订单号",
  required: false,
  rules: [],
  input: true
}, {
  key: "user",
  label: "用户",
  placeholder: "名称/邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "userId",
  label: "UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };